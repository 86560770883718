<template>
  <ys-dialog
      :visible.sync="isVisible"
      @update:visible="visible=>$emit('update:visible', visible)"
      title="食谱信息"
  >

    <div class="page-content padding padding-large">
      <div>
        <span class="font-color-danger">*</span>
        <span class="font-size-medium">名称：</span>
        <ys-input
            class="input"
            placeholder="请输入食谱标题"
            size="large"
            :maxlength="20"
            v-model="name"
        ></ys-input>
      </div>
      <div class="layout-horizontal layout-top margin-top">
        <span class="font-size-medium layout-inflexible">说明：</span>
        <ys-textarea
            class="input margin-left-small font-size-medium"
            placeholder="请输入说明"
            :maxlength="200"
            v-model="message"
        ></ys-textarea>
      </div>
    </div>
    <div slot="footer">
      <ys-button type="secondary" lighting @click="clickCancel">取消</ys-button>
      <ys-button lighting class="margin-left" @click="clickConfirm">确定</ys-button>
    </div>
  </ys-dialog>
</template>

<script>
import httpapi from "@/assets/javascript/httpapi";
import AutosubmitHelper from "@/assets/javascript/autosubmit-helper";
import TextUtils from "@/assets/javascript/text-utils";
import YsButton from "@/components/wedigets/YsButton";
import BusinessState from "@/assets/javascript/business-state";
import YsInput from "@/components/wedigets/YsInput";
import YsTextarea from "@/components/wedigets/YsTextarea";
import YsDialog from "@/components/wedigets/YsDialog";

export default {
  name: "CookeryBookEditDialog",
  components: {YsDialog, YsTextarea, YsInput, YsButton},
  mixins: [httpapi, AutosubmitHelper],
  props: {
    visible: Boolean,
    cookeryBook: Object,
  },
  data() {
    return {

      /**
       * 常量
       */
      BusinessState: BusinessState,


      /**
       * api同步参数
       */
      busyLoadingCode: 0,

      /**
       * 显示弹窗
       */
      isVisible: false,

      /**
       * 食谱信息
       */
      name: null,
      message: null,
    }
  },
  watch: {
    visible: {
      handler() {
        this.isVisible = this.visible;
        if (this.visible) {
          this.loadingCode++;
          this.name = this.cookeryBook.name;
          this.message = this.cookeryBook.message;
        }
      },
      immediate: true,
    },
  },
  methods: {
    clickCancel() {
      this.isVisible = false;
      this.$emit('update:visible', false);
    },
    clickConfirm() {
      if (TextUtils.isBlank(this.name)) {
        this.$message.warning('请输入食谱标题');
        return;
      }
      if (!this.cookeryBook.id) {
        if (!this.cookeryBook.userId) {
          this.addCookeryBook(this.cookeryBook);
        } else {
          this.sendCookeryBookToUser(this.cookeryBook);
        }
      } else {
        this.setCookeryBook(this.cookeryBook);
      }
    },
    isBusy() {
      return this.busyLoadingCode === this.loadingCode;
    },
    addCookeryBook(cookeryBook) {
      if (this.isBusy()) {
        this.$showBusy();
        return;
      }
      this.busyLoadingCode = this.loadingCode;
      this.$reqPost({
        path: `/cookery_book/${cookeryBook.organizationId}/${encodeURIComponent(this.name)}` + (this.message ? `/${encodeURIComponent(this.message)}` : ''),
      })
          .returnWhenCodeMatches()
          .then(res => {
            cookeryBook = res.data;
            window.eventBus.$emit('update:cookery_book', {id: cookeryBook.id});
            this.isVisible = false;
            this.$emit('update:visible', false);
            this.$message.success('保存成功');
            this.$emit('confirm', cookeryBook);
          })
          .catch(() => this.$message.error('保存失败'))
          .complete(() => this.busyLoadingCode = 0);
    },
    sendCookeryBookToUser(cookeryBook) {
      if (this.isBusy()) {
        this.$showBusy();
        return;
      }
      this.busyLoadingCode = this.loadingCode;
      this.$reqPost({
        path: `/user_cookery_book/${cookeryBook.userId}/${cookeryBook.organizationId}/${encodeURIComponent(this.name)}` + (this.message ? `/${encodeURIComponent(this.message)}` : '')
      })
          .returnWhenCodeMatches()
          .then(res => {
            cookeryBook = res.data;
            window.eventBus.$emit('update:cookery_book', {id: cookeryBook.id});
            this.isVisible = false;
            this.$emit('update:visible', false);
            this.$message.success('保存成功');
            this.$emit('confirm', cookeryBook);
          })
          .catch(() => this.$message.error('保存失败'))
          .complete(() => this.busyLoadingCode = 0);
    },
    setCookeryBook(cookeryBook) {
      if (this.isBusy()) {
        this.$showBusy();
        return;
      }
      this.busyLoadingCode = this.loadingCode;
      this.$reqPut({
        path: `/cookery_book/${cookeryBook.id}/${encodeURIComponent(this.name)}` + (this.message ? `/${encodeURIComponent(this.message)}` : ''),
      })
          .returnWhenCodeMatches()
          .then(res => {
            cookeryBook = res.data;
            window.eventBus.$emit('update:cookery_book', {id: cookeryBook.id});
            this.isVisible = false;
            this.$emit('update:visible', false);
            this.$message.success('保存成功');
            this.$emit('confirm', cookeryBook);
          })
          .catch(() => this.$message.error('保存失败'))
          .complete(() => this.busyLoadingCode = 0);
    }

  }
}
</script>

<style scoped>

.is-stopped {
  color: #bec0c0;
  text-decoration: line-through;
}

</style>