<template>
  <div v-if="cookeryBook">
    <div class="padding-horizontal-large padding-vertical font-size-large">
      <back-button @click="$router.back()"></back-button>
      <span>食谱编辑</span>
    </div>
    <div class="layout-vertical layout-center">
      <div class="page-content padding padding-large">
        <div>
          <span class="font-color-danger">*</span>
          <span class="font-size-medium">食谱名称：</span>
          <ys-input
              class="input"
              placeholder="请输入食谱标题"
              size="large"
              :maxlength="20"
              v-model="cookeryBook.name"
              @input="handleNameInput"
          ></ys-input>
        </div>
        <div class="layout-horizontal layout-top margin-top">
          <span class="font-size-medium">食谱说明：</span>
          <ys-textarea
              class="input margin-left-small font-size-medium"
              placeholder="请输入说明"
              :maxlength="200"
              v-model="cookeryBook.message"
              @input="handleMessageInput"
          ></ys-textarea>
        </div>
      </div>
    </div>
    <cookery-book-edit-dialog
        :visible.sync="cookeryBookEditDialogVisible"
        :cookery-book-id="cookeryBook.id"
    ></cookery-book-edit-dialog>
  </div>
</template>

<script>

import httpapi from "@/assets/javascript/httpapi";
import AutosubmitHelper from "@/assets/javascript/autosubmit-helper";
import {CookeryBookUtils} from "@/assets/javascript/cookery-book-utils";
import YsInput from "@/components/wedigets/YsInput";
import YsTextarea from "@/components/wedigets/YsTextarea";
import BackButton from "@/components/backbutton/BackButton";
import CookeryBookEditDialog from "@/pages/cookerybook/component/CookeryBookEditDialog";

const TASK_SUBMIT_COOKERY_BOOK = 0;

const TASK_SUBMIT_COOKERY_BOOK_THEN_EDIT_DIET = 1;

export default {
  name: "CookeryBook",
  components: {CookeryBookEditDialog, BackButton, YsTextarea, YsInput},
  mixins: [httpapi, AutosubmitHelper],
  props: {
    organizationId: Number,
  },
  data() {
    return {

      /**
       * 食谱
       */
      cookeryBook: null,

      /**
       * 每餐单记录
       */
      dietWeeklyRecordList: [],

      /**
       * 修改食谱信息
       */
      cookeryBookEditDialogVisible: false,
    }
  },
  activated() {
    let cookeryBookId = this.$route.query.id;
    if (cookeryBookId) {
      this.loadCookeryBook(cookeryBookId);
    } else {
      this.cookeryBook = null;
      this.cookeryBook = CookeryBookUtils.defaultCookeryBook();
    }
  },
  methods: {
    clickAddDiet() {
      if (this.cookeryBook.id) {
        this.navigateToDietEdit();
      } else {
        this.createTask({
          tag: TASK_SUBMIT_COOKERY_BOOK_THEN_EDIT_DIET,
          runFunc: this.submitCookeryBookThenAddDiet,
        });
      }
    },
    clickDietWeeklyRecord(dietWeeklyRecord) {
      this.navigateToDietEdit(dietWeeklyRecord.weekNum);
    },
    handleNameInput: function () {
      this.createSubmitCookeryBookTask();
    },
    handleMessageInput: function () {
      this.createSubmitCookeryBookTask();
    },
    createSubmitCookeryBookTask() {
      this.createTask({
        tag: TASK_SUBMIT_COOKERY_BOOK,
        runFunc: this.submitCookeryBook,
      })
    },
    loadCookeryBook(id) {
      this.$reqGet({
        path: `/cookery_book/${id}`
      })
          .returnWhenCodeMatches()
          .then(res => {
            this.cookeryBook = res.data;
            this.dietWeeklyRecordList = res.data.dietWeeklyRecordList;
          })
          .catch(() => this.$message.error('加载失败'));
    },
    navigateToDietEdit(weekNum = 0) {
      this.$router.push({
        name: 'cookery_book_diet_list',
        query: {
          id: this.cookeryBook.id,
          weekNum,
        }
      });
    },
    async submitCookeryBook() {
      if (!this.cookeryBook.name) return;
      if (this.cookeryBook.id) {
        await this.setCookeryBook();
      } else {
        await this.addCookeryBook();
      }
    },
    async submitCookeryBookThenAddDiet() {
      await this.submitCookeryBook();
      this.navigateToDietEdit();
    },
    addCookeryBook() {
      return this.$reqPostJSON({
        path: `/cookery_book/${this.organizationId}/${encodeURIComponent(this.cookeryBook.name)}` + (this.cookeryBook.message ? `/${encodeURIComponent(this.cookeryBook.message)}` : ''),
      })
          .returnWhenCodeMatches()
          .then(res => {
            this.cookeryBook.id = res.data.id;
            window.eventBus.$emit('update:cookery_book', {id: this.cookeryBook.id});
          })
          .catch(() => this.$message.error('保存失败'))
          .promise();
    },
    setCookeryBook() {
      return this.$reqPut({
        path: `/cookery_book/${this.cookeryBook.id}/${encodeURIComponent(this.cookeryBook.name)}` + (this.cookeryBook.message ? `/${encodeURIComponent(this.cookeryBook.message)}` : ''),

      })
          .returnWhenCodeMatches()
          .then(() => {
            window.eventBus.$emit('update:cookery_book', {id: this.cookeryBook.id});
          })
          .catch(() => this.$message.error('保存失败'))
          .promise();
    }
  }

}
</script>

<style scoped>

.btn {
  cursor: pointer;
}

.page-content {
  width: 600px;
  background-color: white;
}

.input {
  width: 480px;
}

.week-item {
  cursor: pointer;
  padding: 10px;
}

.week-item:hover {
  opacity: .7;
}

.week-progress-bar {
  width: 64px;
  height: 64px;
  background-color: #f1f1f1;
  border-radius: 10px;
}
</style>