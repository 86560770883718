import BusinessState from "@/assets/javascript/business-state";
import {TimeUtils} from "@/assets/javascript/time-utils";

function defaultCookeryBook() {
    return {
        name: '未命名食谱',
        message: '',
        state: BusinessState.WAITING,
        startDate: TimeUtils.format('yyyy-MM-dd', new Date()),
    }
}

function defaultDiet(weekNum) {
    return {
        weekNum,
        dietCount: 0,
    }
}

export const CookeryBookUtils = {

    defaultCookeryBook,

    defaultDiet,
}